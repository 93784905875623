<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Ciclo biológico de la mosca del Mediterráneo"/><br>
            <img class="mt-2 img-fluid" src="@/assets/home/medfly-1.jpg" alt="">
            <div class="row justify-content-center pt-4">
                <div class="col-sm-4 text-center">
                    <img class="mt-2 img-fluid" src="@/assets/home/medfly-2.jpg" alt=""><br>
                    <img class="mt-2 img-fluid" src="@/assets/home/medfly-3.png" alt="">
                </div>
                <div class="col-sm-6">
                    <img class="mt-2 img-fluid tex-center" src="@/assets/home/medfly-4.png" alt=""><br><br>
                    <p class="text-justify">La mosca del Mediterráneo pone sus huevos dentro de la fruta, de estos huevos nacen las larvas o gusanos que se alimentan de la fruta,
                    cuando las larvas maduran saltan, se entierran, se transforman en pupas y nacen las moscas adultas, para su reproducción se aparean
                    machos con hembras y se repite el ciclo de vida.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
window.scrollTo(0, 0);
</script>
